import React from 'react';
import {ListItem, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import SkillRating from '../atoms/SkillRating';
import clsx from 'clsx';
import themeable from '../../hocs/themeable';
import {cssText, cssBox} from '@ohoareau/css-utils';

export const SkillRatingItem = themeable<SkillRatingItemProps>(theme => ({
    root: {
        ...cssBox(theme, 'skillRatingItem_container'),

        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: '#fff',
        '&:nth-child(even)': {
            backgroundColor: '#fafafa'
        }
    },
    container: {
        display:'flex',
        alignItems:'center',
        flexDirection:'row',
        maxWidth: 1024,
        margin:'auto',
        position:'relative',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    skillRatingLabel: {
        ...cssText(theme, 'standard', 'skillRatingLabel'),
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: 'auto'
        },

        fontFamily: 'Raleway, Arial, sans-serif',
        fontWeight: 'bold',
        fontSize: 16
    },
    actions: {
        position:'unset',
        top:'unset',
        right:'unset',
        transform:'none',
    }
}), ({ classes, className, children, value = 0, onChange, tReady, disabled = false, t = () => {}, ...props }: SkillRatingItemProps) => (
    <ListItem component={"div"} classes={{root: clsx(classes.root, className) }}>
        <div className={classes.container}>
            <ListItemText classes={{primary:classes.skillRatingLabel}}>{children}</ListItemText>
            <ListItemSecondaryAction className={classes.actions}>
                <SkillRating disabled={disabled} onChange={onChange} value={value} {...props} />
            </ListItemSecondaryAction>
        </div>
    </ListItem>
));

export interface SkillRatingItemProps {
    classes?: any,
    className?: string,
    children?: string,
    onChange?: Function,
    value?: number,
    tReady?: boolean
    t?: Function,
    disabled?: boolean
}

export default SkillRatingItem