import {themeable} from "@maarsdev/react-ui";

export const screen = function<T extends BaseScreenProps>(styles: any = {}, component: any) {
    return themeable<T>(styles, component);
}

export interface BaseScreenProps {
    classes?: any,
    context?: any,
}

export default screen