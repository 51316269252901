export abstract class BaseSdk {
    private readonly endpoints: {rest: string, graphql: string};
    private readonly fetch: (url: string, options: any) => Promise<{json: () => any}>;
    constructor({endpoints, fetch}) {
        this.endpoints = endpoints;
        this.fetch = fetch;
    }
    protected async http(service, method: string, uri: string|undefined = undefined, body: any|undefined = undefined) {
        const res = await this.fetch(`${this.endpoints[service]}${uri || ''}`, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: body && JSON.stringify(body)
        });
        return res.json();
    }
    protected async graphqlQuery(query: string) {
        return this.http('graphql', 'post', undefined, {query});
    }
    protected async graphqlMutation(mutation: string, variables: any = {}) {
        return this.http('graphql', 'post', undefined, {mutation, variables});
    }
    protected async restGet(uri: string) {
        return this.http('rest', 'get', uri);
    }
    protected async restPost(uri: string, data: any = {}) {
        return this.http('rest', 'post', uri, data);
    }
    protected async restUpdate(uri: string, data: any = {}) {
        return this.http('rest', 'put', uri, data);
    }
    protected async restDelete(uri: string) {
        return this.http('rest', 'delete', uri);
    }
    protected async restOptions(uri: string) {
        return this.http('rest', 'options', uri);
    }
    protected async restHead(uri: string) {
        return this.http('rest', 'head', uri);
    }
}

export default BaseSdk