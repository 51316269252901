const depassezVous = {
    // button
    typos_standard_button_font_family: 'Raleway, Arial, sans-serif',

    // textarea_input_field
    typos_standard_textarea_input_field_font_family: 'Raleway, Arial, sans-serif',
    typos_standard_textarea_input_field_font_style: 'normal',
    typos_standard_textarea_input_field_font_size: 12,
    typos_standard_textarea_input_field_font_weight: 300,
    typos_standard_textarea_input_field_line_height: '14px',
    font_color_textarea_input_field: '#787371',
    // textarea_input_field_placeholder
    font_color_textarea_input_field_placeholder: '#787371',
    font_color_textarea_input_field_placeholder_opacity: 1,
    // textarea_counter
    typos_standard_textarea_counter_font_family: 'Raleway, Arial, sans-serif',
    typos_standard_textarea_counter_font_style: 'normal',
    typos_standard_textarea_counter_font_size: 8,
    typos_standard_textarea_counter_font_weight: 300,
    typos_standard_textarea_counter_line_height: '9px',
    font_color_textarea_counter: '#000',
    // dialog_title
    typos_standard_dialog_title_font_family: 'Fredoka One, Arial Black, sans-serif',
    typos_standard_dialog_title_font_style: 'normal',
    typos_standard_dialog_title_font_size: 20,
    typos_standard_dialog_title_font_weight: 400,
    font_color_dialog_title: '#fff',
    // dialog_title_small
    typos_standard_dialog_title_small_font_size: 18,
    typos_standard_dialog_title_small_line_height: '20px',
    // progress(_clear)
    font_color_progress_clear: 'rgba(5, 226, 192, 0.2)',
    font_color_progress: '#05E2C0',
    // dialog_header
    background_color_dialog_header: 'rgb(140,110,242)',
    background_dialog_header: 'linear-gradient(90deg, rgba(140,110,242,1) 0%, rgba(5,226,192,1) 100%)',
};

export default {
    default: depassezVous,
    depassezVous,
}
