import * as React from "react";

function SvgStarUnselected(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 39"
      fill="none"
      width={props.width || 35}
      height={props.height || 34}
      fontSize={props.fontSize || "inherit"}
      {...props}
    >
      <path
        d="M20.5 1.13l5.886 11.93.117.235.26.038 13.163 1.913-9.525 9.285-.188.184.044.259 2.248 13.112-11.772-6.19-.233-.123-.232.123-11.773 6.19 2.248-13.112.044-.26-.188-.183-9.525-9.285 13.163-1.913.26-.038.117-.236L20.5 1.13z"
        stroke="#F0B243"
      />
    </svg>
  );
}

export default SvgStarUnselected;
