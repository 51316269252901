import * as React from "react";

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 160 160"
      fill="currentColor"
      width={props.width || 160}
      height={props.height || 160}
      {...props}
    >
      <path
        d="M80 0C35.886 0 0 35.886 0 80s35.886 80 80 80 80-35.886 80-80S124.114 0 80 0z"
        fill="#2FA858"
      />
      <path
        d="M120.547 63.047l-43.334 43.332a6.65 6.65 0 01-4.713 1.955 6.65 6.65 0 01-4.713-1.955L46.12 84.713a6.657 6.657 0 010-9.426 6.657 6.657 0 019.426 0L72.5 92.24l38.621-38.62a6.657 6.657 0 019.426 0 6.66 6.66 0 010 9.427z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCheck;
