import {CircularProgress} from "@material-ui/core";
import React from "react";
import {cssFgColor} from '@ohoareau/css-utils';
import themeable from '../../hocs/themeable';

export const TextareaCircularProgress = themeable<TextareaCircularProgressProps>(theme => ({
    root: {
        position: 'relative',
    },
    bottom: {
        ...cssFgColor(theme, 'progress_clear'),
    },
    top: {
        ...cssFgColor(theme, 'progress'),
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}), ({classes = {}, value = 0, size = 12, t = () => {}, tReady, i18n, ...props}: TextareaCircularProgressProps) => (
    <div className={classes.root}>
        <CircularProgress variant={'determinate'} className={classes.bottom} size={size} value={100} {...props} />
        <CircularProgress variant={'determinate'} className={classes.top} classes={{circle: classes.circle}} size={size} value={value} {...props} />
    </div>
));

export interface TextareaCircularProgressProps {
    classes?: any,
    value?: number,
    size?: number,
    t?: Function,
    i18n?: any,
    tReady?: boolean,
}

export default TextareaCircularProgress