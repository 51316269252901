import themeable from '../../hocs/themeable';
import {useMediaQuery, useTheme} from '@material-ui/core';
import React from 'react';
import {cssText} from "@ohoareau/css-utils";

export const SkillRatingIconContainer = themeable<SkillRatingIconContainerProps>(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        transform: 'none',
        width: 65,
        [theme.breakpoints.down('sm')]: {
            width: 53
        },
    },
    ratingValue: {
        display: 'block',
        textAlign: 'center',
    },
    ratingLabel: {
        ...cssText(theme, 'standard', 'skillRatingRankLabel'),
        display: 'block',

        fontFamily: 'Raleway, Arial, sans-serif',
        fontSize: 8,
        fontWeight: 300,
        color: '#000',
    },
}), ({classes, t = () => {}, children, value}: SkillRatingIconContainerProps) => {
    const theme = useTheme();
    const smDevice = useMediaQuery(theme.breakpoints.down('sm'));
    let label;
    switch (value) {
        case 1: label = t('skill_rating_level_1_label'); break;
        case 3: label = smDevice ? 'Interméd.' : 'Intermédiaire'; break;
        case 5: label = 'Expert';  break;
        default: label = '';
    }
    return (
        <div className={classes.root}>
            <div className={classes.ratingValue}>{children}</div>
            <div className={classes.ratingLabel}>{label}</div>
        </div>
    );
});

export interface SkillRatingIconContainerProps {
    classes?: any,
    onChange: Function,
    theme?: string,
    value?: any,
    t?: Function,
    tReady?: boolean,
    children?: any,
}

export default SkillRatingIconContainer