import React from "react";
import {Dialog, themeable} from "@maarsdev/react-ui";
import useWorkflow from "../hooks/useWorkflow";
import {DialogContent} from "@material-ui/core";

const buildOnClick = (target, props) => {
    switch (target) {
        case '@end': return e => {
            e && e.stopProgration && e.stopProgration();
            props.onClose();
        }
        case '@submit': return e => {
            e && e.stopProgration && e.stopPropagation();
            props.onSubmit();
        }
        case '@next': return e => {
            e && e.stopProgration && e.stopPropagration();
            props.onNext();
        }
        case '@back': return e => {
            e && e.stopProgration && e.stopPropagation();
            props.onBack();
        }
        default: return e => {
            e && e.stopProgration && e.stopPropagation();
        };
    }
}
const useDialogActions = (actions, props) => {
    return (actions || []).map(a => {
        return {
            variant: a.type === 'primary' ? 'contained' : 'outlined',
            color: a.type === 'primary' ? 'primary' : 'primary',
            onClick: buildOnClick(a.target, props),
            label: a.label,
            disabled: a.isDisabled ? a.isDisabled(props.state) : false,
        }
    });
}
export const product = function<T extends BaseProductProps>(workflow: any = {}, {onSubmit = undefined}: {onSubmit?: Function} = {}, styles: any = {}) {
    return themeable<T>({
        ...styles,
        root: {
            ...(styles.root || {}),
        },
        content: {
            ...(styles.content || {}),
        },
    }, ({classes = {}, onSubmit: localOnSubmit, onClose, onCancel, context = {}}: T) => {
        const {component: Screen, actions, ...props} = useWorkflow(workflow, {context, onClose, onCancel: onCancel || onClose, onSubmit: localOnSubmit || onSubmit});
        const builtActions = useDialogActions(actions, props);

        return (
            <Dialog className={classes.root} open={true} onClose={onClose} actions={builtActions} {...props}>
                <DialogContent className={classes.content}>
                    <Screen {...props} actions={actions} />
                </DialogContent>
            </Dialog>
        );
    });
}

export interface BaseProductProps {
    classes?: any,
    onClose?: Function,
    onCancel?: Function,
    context?: any,
    onSubmit?: Function,
}

export default product