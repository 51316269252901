import {useState} from 'react';
import buildConfig from '../utils/buildConfig';
import {config} from '../types';

const useConfig = (rawConfig, context): [config, any, any] => {
    const [visible, setVisible] = useState(false);
    const [opened, setOpened] = useState(false);
    const [config, setConfig] = useState(undefined as any);
    config || buildConfig(rawConfig, context).then(config => {
        setConfig(config);
        (visible !== config.visible) && setVisible(config.visible);
        (opened !== config.opened) && setOpened(config.opened);
    }).catch(e => {
        console.log(e);
    })
    return [(config || {configured: false, visible, opened}) as config, {visible, opened}, {setVisible, setOpened}];
}

export default useConfig