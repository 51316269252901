import React, {useCallback} from 'react';
import {themeable, SkillRatingList} from '@maarsdev/react-ui';
import {CircularProgress} from '@material-ui/core';
import useWorkflowStateSdkItemPage from "../../../hooks/useWorkflowStateSdkItemPage";

export const RankScreen = themeable({
    loading: {
        minHeight: 300,
        padding: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
}, ({ classes = {}, state = {}, t, onUpdateState, context }: any) => {
    const {data: skills} = useWorkflowStateSdkItemPage(context, state, onUpdateState, 'allSkills', 'findPublicSkills');
    const onChange = useCallback(userSkills => onUpdateState({skills: {...userSkills}}), [onUpdateState]);
    if (!skills) return (
        <div className={classes.loading}>
            <CircularProgress size={100} style={{color:"#8c6ef2"}} />
        </div>
    );
    return (
        <div>
            <SkillRatingList maxValues={5} values={state.skills || {}} items={skills.items || []} onChange={onChange} />
        </div>
    );
});

export default RankScreen;
