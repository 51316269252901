import React from 'react';
import {Fab} from '@material-ui/core';
import {themeable} from '@maarsdev/react-ui';

export const FabFormat = themeable<FabFormatProps>({
    root: {
    }
}, ({onOpen}: FabFormatProps) => (
    <Fab color={'primary'} onClick={onOpen}>
        H
    </Fab>
));

export interface FabFormatProps {
    onOpen?: any,
}

export default FabFormat