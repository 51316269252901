import React, {useMemo} from 'react';
import {ThemesProvider} from "./ThemesContext";
import {ThemeProvider} from "./ThemeContext";
import {UserProvider} from "./UserContext";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core';
import i18nFactory from '../i18n';
import {I18nextProvider} from 'react-i18next'

const defaultMuiTheme = createMuiTheme();

//const cachedI18nInstances = {};

const getI18nSingletonFromLang = (lang: string|undefined = undefined, translations: any|undefined = undefined, extraTranslations: any|undefined = undefined) => {
    return i18nFactory({lng: lang, resources: translations, extraResources: extraTranslations});
/*
    const key = lang || 'default';
    !cachedI18nInstances[key] && (cachedI18nInstances[key] = i18nFactory({lng: lang, resources: translations, extraResources: extraTranslations}));
    return cachedI18nInstances[key];
 */
};

export const UIProvider = ({user, lang, translations, extraTranslations, theme, themes, muiTheme, children}: UIProviderProps) => {
    const i18n = useMemo(() => {
        return getI18nSingletonFromLang(lang, translations, extraTranslations)
    }, [lang, translations, extraTranslations]);
    return (
        <I18nextProvider i18n={i18n}>
            <UserProvider value={user}>
                <ThemeProvider value={theme || 'default'}>
                    <MuiThemeProvider theme={muiTheme || defaultMuiTheme}>
                        {themes
                            ? <ThemesProvider value={themes as any}>{children}</ThemesProvider>
                            : children
                        }
                    </MuiThemeProvider>
                </ThemeProvider>
            </UserProvider>
        </I18nextProvider>
    );
}

export interface UIProviderProps {
    themes?: any,
    muiTheme?: any,
    children?: any,
    user?: any,
    theme?: string,
    lang?: string,
    translations?: any,
    extraTranslations?: any,
}
export default UIProvider