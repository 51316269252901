import React from 'react';
import screen from "../../../hocs/screen";

export const ErrorScreen = screen({
    root: {
        minHeight: 300,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 20,
        display: "flex",
        fontWeight: "bold",
    }
}, ({classes, t}) => (
    <div className={classes.root}>
        {t("p_skillsProposal_error_text")}
    </div>
));

export default ErrorScreen
