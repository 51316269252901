import {useCallback} from 'react';
import useConfig from './useConfig';

export const useWidget = (rawConfig = {}, context = {}) => {
    const [config, {opened, visible}, {setOpened, setVisible}] = useConfig(rawConfig, context);
    const onOpen = useCallback(() => {
        setOpened(true);
    }, [setOpened]);
    const onClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);
    const onHide = useCallback(() => {
        setVisible(false);
    }, [setVisible]);
    const onDisplay = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    return {config, visible, opened, onOpen, onClose, onHide, onDisplay, context};
};

export default useWidget