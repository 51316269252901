import React from 'react';
import {DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, IconButton} from '@material-ui/core';
import {cssText, cssBox} from '@ohoareau/css-utils';
import DynamicIcon from './DynamicIcon';
import themeable from "../../hocs/themeable";

export const DialogTitle = themeable<DialogTitleProps>(theme => ({
    root: {
        ...cssText(theme, 'standard', 'dialog_header', 'center'),
        ...cssBox(theme, 'default', 'dialog_header'),
        '& h1': {
            ...cssText(theme, 'standard', 'dialog_title', 'center'),
            paddingRight: 22,
            [theme.breakpoints.down('sm')]: {
                ...cssText(theme, 'standard', 'dialog_title_small', 'left'),
            }
        }
    },
    title: {
        position: 'relative',
        maxWidth: 1080,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto 0 0',
            ...cssText(theme, 'standard', 'dialog_title_small', 'left'),
        }
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translate(0, -50%)',
        color: '#fff',
        padding: 0,
        width: 20,
        height: 20,
        '&:hover': {
            backgroundColor: 'inherit'
        }
    },
}), ({children, classes, onClose, t = () => {}, tReady, i18n, ...props}: DialogTitleProps) => (
    <MuiDialogTitle disableTypography className={classes.root} {...props}>
        <h1 className={classes.title}>
            {children}
            <IconButton
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
                aria-label={'close'}
                className={classes.closeButton}
                onClick={onClose}>
                <DynamicIcon type={'cross'} />
            </IconButton>
        </h1>
    </MuiDialogTitle>
));

export interface DialogTitleProps extends MuiDialogTitleProps {
    classes?: any,
    onClose?: any,
    t?: Function,
    i18n?: any,
    tReady?: boolean,
}

export default DialogTitle