import React, {useCallback, useState} from 'react';
import {Button, ButtonProps, themeable} from "@maarsdev/react-ui";
import useContextSdkMutation from "../../hooks/useContextSdkMutation";

export const CreateRelationshipWithExpertButton = themeable<CreateRelationshipWithExpertButtonProps>({

}, ({t = () => {}, context, expert, onComplete, ...props}: CreateRelationshipWithExpertButtonProps) => {
    const [loading, setLoading] = useState(false);
    const [save] = useContextSdkMutation(context, 'createPublicSourceUserRelation');
    const handleClick = useCallback(() => {
        setLoading(true);
        save({
            variables: {
                sourceCode: context.source || "skill_expert_search",
                sourceUserId: context.user || "0",
                sourceEmail: context.email || 'widget@maars.cc',
                sourceFirstName: context.firstName || 'FirstName',
                sourceLastName: context.lastName || 'LastName',
                user: expert.id || "0",
            }
        }).then(() => {
            onComplete && onComplete();
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.error(e);
        });
    }, [save, context, expert, setLoading, onComplete]);

    return (
        <Button
            loading={loading}
            loadingLabel={t("p_skillExpertSearch_solicitSkill_label_loading")}
            onClick={handleClick}
            {...props}
        >
            {t("p_skillExpertSearch_solicitSkill_label")}
        </Button>
    );
});

export interface CreateRelationshipWithExpertButtonProps extends ButtonProps {
    context: any,
    expert: any
    onComplete?: Function
}

export default CreateRelationshipWithExpertButton