import React from 'react';
import screen from "../../../hocs/screen";
import {Typography} from "@material-ui/core";

export const EndScreen = screen({
    root: {
        maxWidth: 768,
        margin: "auto",
        padding: 20,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: "Fredoka One",
        padding: "40px 0",
    },
    p: {
        fontSize: 16,
        padding: "10px 0",
    },
    success: {
        background: '#2FA858',
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: 3,
        textAlign: 'center',
        maxWidth: 350,
        padding: 20,
        margin: "40px auto",
    }
}, ({classes = {}, t}: any) => (
    <div className={classes.root}>
        <Typography className={classes.title}>{t('p_skillExpertSearch_end_title')}</Typography>
        <Typography className={classes.p}>{t('p_skillExpertSearch_end_text_1')}</Typography>
        <Typography className={classes.success}>{t('p_skillExpertSearch_end_text_2')}</Typography>
    </div>

));
export default EndScreen
