import React, {memo} from 'react';
import {capitalize} from "@material-ui/core";
import Loadable from '@loadable/component';

export const DynamicIcon = memo(({type, ...props}: DynamicIconProps) => {
    const name = (type || '').split('-').map(capitalize).join('');
    const Comp = Loadable(() =>
        import(`./images/icons/${name}`)
            .catch(() => () => <div className={props.className} style={{width: props.width, height: props.height}} />))
    ;
    return <Comp {...props} />;
});

export interface DynamicIconProps extends React.SVGProps<SVGSVGElement> {
    type?: string,
}

export default DynamicIcon