import React from 'react';
import {themeable, Button} from '@maarsdev/react-ui';
import ConfigIcon from '../ConfigIcon';

export const ButtonFormat = themeable<ButtonFormatProps>({
    root: {
    }
}, ({classes, config = {}, onOpen}: ButtonFormatProps) => (
    <Button
            startIcon={<ConfigIcon config={config} propertyKey={'format'} propertyValue={'startIcon'} />}
            endIcon={<ConfigIcon config={config} propertyKey={'format'} propertyValue={'endIcon'} />}
            theme={(config || {}).__xid_theme}
            className={classes.root}
            onClick={onOpen}
            stretchable={true}
    >
        {(config || {}).format.label || '...'}
    </Button>
));

export interface ButtonFormatProps {
    classes?: any,
    config?: any,
    onOpen?: any,
}

export default ButtonFormat