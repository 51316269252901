import React, {useCallback} from 'react';
import {Button} from '@maarsdev/react-ui';
import {CircularProgress} from "@material-ui/core";
import useWorkflowStateSdkItemPage from "../../../hooks/useWorkflowStateSdkItemPage";
import screen from "../../../hocs/screen";

export const SkillsScreen = screen(theme => ({
    root: {
        padding: 40
    },
    list: {
        maxWidth: 600,
        margin: "auto",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: 40,
        gap: 40,
        gridAutoRows: 'minmax(90px, auto)',
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
            gridGap: 20,
            gap: 20,
        }
    }
}), ({context = {}, state, onUpdateState, onNext, classes = {}}: any) => {
    const {data: skills} = useWorkflowStateSdkItemPage(context, state, onUpdateState, 'skills', 'findPublicSkills');
    const handleNext = useCallback((skill) => {
        onNext && onNext({skill});
    }, [onNext]);
    if (!skills) return <CircularProgress size={48} />;
    return (
        <div className={classes.root}>
            <div className={classes.list}>
                {((skills || {}).items || []).map((skill, i) => (
                    <Button key={i} color={"primary"} variant={"outlined"} shape={"squared"} onClick={() => handleNext(skill)}>{skill.name}</Button>
                ))}
            </div>
        </div>
    );
});

export default SkillsScreen
