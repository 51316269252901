import React from 'react';
import {Backdrop} from '@material-ui/core';
import {themeable} from '@maarsdev/react-ui';

export const ModalDisplay = themeable<ModalDisplayProps>({
    root: {
        backgroundColor: 'rgba(200, 200, 200, 0.8)',
    },
}, ({classes, onClose, children}: ModalDisplayProps) => (
    <Backdrop className={classes.root} open={true} onClick={onClose}>
        {children}
    </Backdrop>
));

export interface ModalDisplayProps {
    classes?: any,
    onClose?: any,
    children?: any,
}

export default ModalDisplay