import {useEffect, useState} from "react";

export const useContextSdkItem = (context, methodName): {data: {items: any[]}|undefined, loading: boolean} => {
    const [data, setData] = useState(undefined);
    useEffect(() => {
        (context.sdk[methodName])().then(item => {
            setData(item);
        });
    }, [context, methodName, setData]);
    return {data, loading: undefined === data};
};

export default useContextSdkItem
