import React from 'react';
import {user} from "../../types";
import {themeable} from "../../hocs";

/**
 * Composant qui pourra évoluer pour gérer les différentes façons d'afficher l'identité d'un utilisateur
 * Pour le moment ça sera "Prénom N." (Le prénom suivi du nom sur une lettre avec un point)
 */
export const ProfileName = themeable<ProfileNameProps>({},({user = {}}: ProfileNameProps) => (
    <>{(user.firstName || "")}{user.lastName && ` ${user.lastName.substring(0, 1)}.`}</>
));

interface ProfileNameProps {
    user?: user
}

export default ProfileName