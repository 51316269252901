import React, {useCallback} from 'react';
import themeable from '../../hocs/themeable';
import SkillRatingItem from './SkillRatingItem';

export const SkillRatingListItem = themeable<SkillRatingListItemProps>({
}, ({id, name, value, onChange, disabled = false}: SkillRatingListItemProps) => {
    const handleChange = useCallback(v => {
        onChange && onChange({[id]: v});
    }, [onChange, id]);
    return <SkillRatingItem disabled={disabled} name={id} value={value} onChange={handleChange}>{name}</SkillRatingItem>;
});

export interface SkillRatingListItemProps {
    classes?: any,
    id: string,
    name: string,
    value?: number,
    onChange?: Function,
    disabled?: boolean,
}

export default SkillRatingListItem