import product from "../../hocs/product";
import workflow from '../../workflows/skillExpertSearch';

export const SkillExpertSearchProduct = product(
    workflow,
    {
        onSubmit: values => {
            console.log(values);
        },
    },
    {
        content: {
            padding: 0,
            minHeight: "500px",
            alignItems: "center",
            justifyContent: "center",
        },
    }
);

export default SkillExpertSearchProduct