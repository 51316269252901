import React from 'react';
import screen from "../../../hocs/screen";
import {CircularProgress, Grid, Typography} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import {ProfileName, Textarea} from "@maarsdev/react-ui";
import useWorkflowStateSdkItemPage from "../../../hooks/useWorkflowStateSdkItemPage";
import {CreateRelationshipWithExpertButton} from "../../atoms/CreateRelationshipWithExpertButton";

export const ExpertScreen = screen(theme => ({
    root: {
        width: "100%",
        padding: 0,
    },
    title: {
        textAlign: "center",
        fontSize: 16,
        margin: "auto",
        padding: 30,
        color: "#000",
        lineHeight: "14px",
        fontWeight: "bold",
    },
    rank: {
        padding: 10,
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: 0
        },
    },
    skill: {
        fontSize: 16,
        fontWeight: "bold",
    },
    skillRow: {
        padding: "26px 10px",
        '&:nth-child(even)': {
            background: '#fafafa'
        }
    },
    skillRowInner: {
        maxWidth: 1024
    },
    comments: {
        padding: "10px 50px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: 0
        },
    },
    actions: {
        padding: 10,
        alignSelf: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: [10, 0],
            marginBottom: 40
        },
    }
}), ({ context, state, onUpdateState, onNext, classes = {}, t = () => {} }: any) => {
    const {data: skills}: any = useWorkflowStateSdkItemPage(context, state, onUpdateState, `expert_${state.selectedExpert.id}_skills`, 'findPublicSkillsByUser', state.selectedExpert.id);
    if (!skills) return <CircularProgress size={48} />;
    return (
        <div className={classes.root}>
            <Typography className={classes.title}><ProfileName user={state.selectedExpert} /></Typography>
            <Grid container direction={"column"} justify={"center"} alignItems={"stretch"}>
                {(skills.items || []).map(item => (
                    <Grid container key={item.skill} justify={"center"} alignItems={"stretch"} className={classes.skillRow}>
                        <Grid container direction={"row"} className={classes.skillRowInner}>
                            <Grid xs={false} sm={2} md={2} item className={classes.rank}>
                                <Typography className={classes.skill}>{item.skillName}</Typography>
                                <Rating size={"small"} readOnly value={item.level} />
                            </Grid>
                            <Grid xs={false} sm={7} md={7} item className={classes.comments}>
                                <Textarea defaultValue={item.comment} readOnly fullWidth />
                            </Grid>
                            <Grid xs={false} sm={3} md={3} item className={classes.actions}>
                                <CreateRelationshipWithExpertButton context={context} expert={state.selectedExpert} onComplete={onNext} fullWidth dense={true} variant={"contained"} />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
});

export default ExpertScreen
