import React from 'react';
import component from '@maarsdev/react-component';
import clsx from 'clsx';
import Display from './Display';
import Format from './Format';
import {config} from '../types';
import Product from './Product';

export const Widget = component<WidgetProps>({
    root: {
    },
}, ({ classes = {}, className, ...props }: WidgetProps) => (
    <div className={clsx(classes.root, className)}>
        {!!props.visible && !!props.opened && (
            <Display {...props} {...(props.config.display || {})} id={props.config.__xid_display || 'default'}>
                <Product {...props} {...(props.config.product || {})} id={props.config.__xid_product || 'default'} />
            </Display>
        )}
        {!!props.visible && !props.opened && <Format {...props} {...(props.config.format || {})} id={props.config.__xid_format || 'default'} />}
    </div>
));

export interface WidgetProps {
    classes?: any,
    className?: string,
    config: config,
    context?: any,
    opened?: boolean,
    visible?: boolean,
}

export default Widget