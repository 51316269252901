import React from 'react';
import {themeable} from '@maarsdev/react-ui';

export const ImageFormat = themeable({
    image: {
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0)',
        boxSizing: 'border-box',
        '&:hover': {
            borderColor: 'orange',
        }
    }
}, ({ classes, image, onOpen }) => (
    <img alt={'my image button'} src={image} className={classes.image} onClick={onOpen} />
));

export default ImageFormat