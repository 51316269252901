export default {
    "fr-FR": {
        "translation": {
            'skill_rating_level_1_label': "Débutant",
            'dialog_action_cancel_label': "Annuler",
            'dialog_action_next_label': "Continuer",
            'dialog_action_back_label': "Retour",
            'dialog_action_close_label': "Fermer",
            'dialog_action_submit_label': "Envoyer",
            'dialog_action_save_label': "Sauvegarder",
            'dialog_action_quit_label': "Quitter",
        },
    },
    "en-US": {
        "translation": {
            'skill_rating_level_1_label': "Beginner",
        },
    },
}
