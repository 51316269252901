import React, {useCallback} from 'react';
import Rating from '@material-ui/lab/Rating';
import Button from './Button';
import DynamicIcon from './DynamicIcon';
import themeable from '../../hocs/themeable';
import SkillRatingIconContainer from '../nucleons/SkillRatingIconContainer';

export const SkillRating = themeable<SkillRatingProps>(theme => ({
    root: {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            gridGap: 10,
            flexDirection: 'column-reverse'
        }
    },
    ratingRoot: {
        fontSize: '2.7rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    },
    ratingIcon: {
        display: 'block',
        height: '45px',
        [theme.breakpoints.down('sm')]: {
            height: '35px'
        }
    },
    ratingIconActive: {
        transform: 'none'
    },
    ratingNone: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1)
        }
    },
}), ({ classes, value = 0, t = () => {}, i18n, tReady, onChange, disabled = false, ...props }: SkillRatingProps) => {
    const handleChangeValue = useCallback(value => {
        onChange && onChange(value || 0);
    }, [onChange]);
    return (
        <div className={classes.root}>
            <div className={classes.ratingNone}>
                <Button variant={"outlined"} color={"secondary"} shape={"squared"} size={"small"} active={value === 0} onClick={() => handleChangeValue(0)}>AUCUNE</Button>
            </div>
            <Rating
                disabled={disabled}
                icon={<DynamicIcon type={'star-selected'} />}
                emptyIcon={<DynamicIcon type={'star-unselected'} />}
                classes={{
                    root: classes.ratingRoot,
                    icon: classes.ratingIcon,
                    iconActive: classes.ratingIconActive
                }}
                onChange={(e, newValue) => handleChangeValue(newValue)}
                value={value}
                IconContainerComponent={SkillRatingIconContainer}
                {...props}
            />
        </div>
    );
});

export interface SkillRatingProps {
    classes?: any,
    className?: string,
    onChange: Function,
    theme?: string,
    value?: number,
    tReady?: boolean
    t?: Function,
    i18n?: any,
    disabled?: boolean,
}

export default SkillRating