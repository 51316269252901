export default {
    componentRepository: 'skillsProposal',
    defaultScreen: 'rank',
    screens: {
        rank: {
            title: 'p_skillsProposal_screen_rank_title',
            actions: [
                {type: 'secondary', label: 'close', target: '@end'},
                {type: 'primary', label: 'next', target: '@next', isDisabled: ({skills}) => !(skills && typeof(skills) === "object" && Object.keys(skills).length > 0)},
            ],
            transitions: [
                {screen: 'comments', test: ({skills}) => skills && typeof(skills) === "object" && Object.keys(skills).length > 0}
            ]
        },
        comments: {
            title: 'p_skillsProposal_screen_comments_title',
            actions: [
                {type: 'secondary', label: 'back', target: '@back'},
                {type: 'primary', label: 'save', target: '@next'},
            ],
            transitions: [
                {screen: 'save'},
            ]
        },
        save: {
            title: 'p_skillsProposal_screen_save_title',
            actions: [
                {type: 'secondary', label: 'back', target: '@back'},
                {type: 'primary', label: 'save', target: '@next'},
            ],
            transitions: [
                {screen: 'thanks'}
            ]
        },
        thanks: {
            title: 'p_skillsProposal_screen_thanks_title',
            actions: [
                {type: 'primary', label: 'quit', target: '@end'},
            ],
        },
        error: {
            title: 'p_skillsProposal_screen_error_title',
            actions: [
                {type: 'secondary', label: 'back', target: '@back'},
                {type: 'primary', label: 'cancel', target: '@end'},
            ],
        }
    }
}