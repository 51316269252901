export default {
    format: {
        '00': 'default',
        '01': 'button',
        '02': 'image',
        '03': 'fab',
    },
    display: {
        '00': 'default',
        '01': 'fullsize',
        '02': 'modal',
    },
    product: {
        '00': 'default',
        '01': 'skillsProposal',
        '02': 'skillExpertSearch',
        '03': 'donation',
        '04': 'challengeFeedback',
        '05': 'form',
    },
    theme: {
        '00': 'default',
        '01': 'depassezVous',
    }
}