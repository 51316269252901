import {useCallback, useMemo, useState} from "react";
import loadable from '@loadable/component';
const screenFactory = s => loadable(() => import(`../components/screens/${s}Screen`));

const useBuiltDefinition = def => useMemo(() => ({
    ...def,
    screens: Object.entries({
        ...def.screens || {},
    }).reduce((acc, [k, v]) => (Object.assign as any)(acc, {[k]: {
        name: k,
        ...((v as any) || {}),
        type: 'screen',
        component: screenFactory(((v as any) || {}).component || `${def.componentRepository}/${k.slice(0, 1).toUpperCase()}${k.slice(1)}`),
    }}), {}),
}), [def]);

const getScreen = (definition, name) => {
    switch (name) {
        case '@end': return {type: 'end', name: '@end'};
        case '@submit': return {type: 'submit', name: '@submit'};
        default: return definition.screens[name] || definition.screens['error'];
    }
}

const getNextScreen = (currentScreen, definition, state) => getScreen(
    definition,
    ((currentScreen.transitions || []).find(t => !t.test || t.test(state, currentScreen, definition)) || {screen: 'error'}).screen
);

export const useWorkflow = (def, {context, onClose = undefined, onCancel = undefined, onSubmit = undefined}: {context?: any, onClose?: any, onCancel?: any, onSubmit?: any}) => {
    const definition = useBuiltDefinition(def);
    const [history, setHistory] = useState([] as string[]);
    const [state, setState] = useState({});
    const [screen, setScreen] = useState(() => getScreen(definition, definition.defaultScreen));

    const onUpdateState = useCallback((data = {}) => {
        setState({...state, ...data});
    }, [setState, state]);
    const onNext = useCallback((data: any = undefined) => {
        const newState = data ? {...state, ...data} : state;
        setState(newState);
        const nextScreen = getNextScreen(screen, definition, newState);
        (!history.length || (screen.name !== history[history.length - 1])) && setHistory([...history, screen.name]);
        switch (nextScreen.type) {
            case 'screen': setScreen(nextScreen); break;
            case 'end':
                onClose ? onClose() : (onCancel ? onCancel() : undefined);
                break;
            case 'submit':
                onSubmit && onSubmit(state);
                onClose && onClose();
                break;
            default: break;
        }
    }, [screen, setScreen, definition, state, setHistory, history, onCancel, onSubmit]);

    const onBack = useCallback((data: any = undefined) => {
        const newState = data ? {...state, ...data} : state;
        setState(newState);
        const newHistory = [...history];
        const nextScreenName = newHistory.length ? newHistory.pop() : definition.defaultScreen;
        setHistory(newHistory);
        setScreen(getScreen(definition, nextScreenName));
    }, [setHistory, state, history, setScreen, definition]);

    const extraProps = {onNext, onBack, onCancel, onClose, context, onUpdateState};

    return {...screen, ...extraProps, state};
};

export default useWorkflow