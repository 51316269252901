import React, {useCallback} from 'react';
import {Button, Textarea, ProfileName} from '@maarsdev/react-ui';
import {Box, CircularProgress, Grid, Typography} from '@material-ui/core';
import screen from "../../../hocs/screen";
import Rating from "@material-ui/lab/Rating";
import useWorkflowStateSdkItemPage from "../../../hooks/useWorkflowStateSdkItemPage";
import CreateRelationshipWithExpertButton from "../../atoms/CreateRelationshipWithExpertButton";

export const ExpertsScreen = screen(theme => ({
    root: {
        width: "100%",
        padding: 0,
    },
    title: {
        color: "#8C6EF2",
        fontSize: 16,
        lineHeight: "23px",
        margin: "auto",
        padding: "30px 10px",
        fontFamily: "Fredoka One",
        maxWidth: 1024
    },
    skillRow: {
        padding: "26px 10px",
        '&:nth-child(even)': {
            background: '#fafafa'
        }
    },
    skillRowInner: {
        maxWidth: 1024
    },
    profile: {
        padding: 10,
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: "Raleway",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: 0
        },
    },
    comments: {
        padding: "10px 50px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: 0
        },
    },
    actions: {
        padding: 10,
        alignSelf: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: [10, 0],
            marginBottom: 40
        },
    },
    noExpert: {
        fontFamily: "Raleway",
        fontSize: 16,
        textAlign: "center",
        color: "#666",
    },
}), ({ classes = {}, context, state, onUpdateState, t = () => {}, onNext }: any) => {
    const {data: usersSkill}: any = useWorkflowStateSdkItemPage(context, state, onUpdateState, `skill_${state.skill.id}_experts`, 'findPublicExpertsBySkill', state.skill.id);
    const goNext = useCallback(item => () => {
        onNext && onNext({selectedExpert: item});
    }, [onNext]);
    if (!usersSkill) return <CircularProgress size={48} />;
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>{state.skill.name}</Typography>
            <Grid container direction={"column"} justify={"center"} alignItems={"stretch"}>
            {(usersSkill.items || []).length === 0 && <Box className={classes.noExpert}><Typography>{t("p_skillExpertSearch_no_expert")}</Typography></Box>}
            {(usersSkill.items || []).map(item => (
                <Grid container key={item.id} justify={"center"} alignItems={"stretch"} className={classes.skillRow}>
                    <Grid container direction={"row"} className={classes.skillRowInner}>
                        <Grid xs={false} sm={2} md={2} item className={classes.profile}>
                            <div><ProfileName user={item} /></div>
                            <Rating size={"small"} readOnly value={item.level} />
                        </Grid>
                        <Grid xs={false} sm={6} md={6} item className={classes.comments}>
                            <Textarea defaultValue={item.comment} readOnly fullWidth />
                        </Grid>
                        <Grid xs={false} sm={4} md={4} item className={classes.actions}>
                            <Button style={{marginBottom:10}} fullWidth variant={"outlined"} onClick={goNext(item)}>{t("p_skillExpertSearch_viewProfile_label")}</Button>
                            <CreateRelationshipWithExpertButton dense={true} fullWidth context={context} expert={item} onComplete={goNext(false)} />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            </Grid>
        </div>
    )
});

export default ExpertsScreen
