import * as React from "react";

function SvgCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      width={props.width || 20}
      height={props.height || 20}
      {...props}
    >
      <rect
        x={17.871}
        width={2}
        height={25.273}
        rx={1}
        transform="rotate(45 17.87 0)"
        fill="#fff"
      />
      <rect
        x={19.285}
        y={17.871}
        width={2}
        height={25.273}
        rx={1}
        transform="rotate(135 19.285 17.87)"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCross;
