export default {
    translation: {
        p_skillExpertSearch_screen_welcome_title: "Trouvez l'expert dans une compétence",
        p_skillExpertSearch_screen_skills_title: "Choisissez la compétence qui vous intéressse",
        p_skillExpertSearch_screen_experts_title: "Cliquez sur l'expert que vous souhaitez solliciter",
        p_skillExpertSearch_screen_expert_title: "Vous pouvez solliciter cet expert",
        p_skillExpertSearch_welcome_text_1: "Dans l'étape suivante, vous allez pouvoir choisir une compétence et trouver les experts associés.",
        p_skillExpertSearch_welcome_text_2: "Vous pourrez ensuite les solliciter pour progresser.",
        p_skillExpertSearch_skill_label: "Compétence",
        p_skillExpertSearch_viewProfile_label: "Voir le profil",
        p_skillExpertSearch_solicitSkill_label: "Solliciter l'expertise",
        p_skillExpertSearch_solicitSkill_label_loading: "Sollicitation...",
        p_skillExpertSearch_end_title: "Merci d'avoir partagé vos connaissances",
        p_skillExpertSearch_end_text_1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse lacinia leo sed semper maximus. Fusce quam tortor, tempus eget augue nec, lacinia egestas enim. Morbi in consectetur magna, sit amet feugiat velit. Morbi ipsum risus, egestas eget tincidunt tempus, commodo non felis. Etiam nec tellus viverra, aliquam quam ut, posuere ante. Fusce bibendum vitae nulla ut dapibus. Curabitur metus felis, bibendum non molestie ac, tempor id velit.",
        p_skillExpertSearch_end_text_2: "Votre demande a bien été envoyée",
        p_skillExpertSearch_screen_end_title: "L'Expert a été contacté !",
        p_skillExpertSearch_no_expert: "Il n'y a pas encore d'expert dans cette compétence",
    },
}