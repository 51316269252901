import React from 'react';
import {Typography} from '@material-ui/core';
import screen from "../../../hocs/screen";

export const WelcomeScreen = screen({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        padding: ""
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: "Fredoka One",
        padding: 20,
    }
}, ({classes = {}, t}: any) => (
    <div className={classes.root}>
        <Typography className={classes.title}>{t('p_skillExpertSearch_welcome_text_1')}</Typography>
        <Typography className={classes.title}>{t('p_skillExpertSearch_welcome_text_2')}</Typography>
    </div>
));

export default WelcomeScreen
