export default {
    componentRepository: 'donation',
    defaultScreen: 'welcome',
    screens: {
        welcome: {
            title: 'p_donation_screen_welcome_title',
            actions: [
                {type: 'primary', label: 'quit', target: '@end'},
            ],
            transitions: [
            ]
        },
        error: {
        }
    }
}