import React, {ComponentType} from "react";
import component from '@maarsdev/react-component';
import {Styles} from "@material-ui/core/styles/withStyles";
import {ThemeConsumer, ThemesConsumer} from '../components';
import {ThemeProvider} from '@material-ui/core/styles';
import {withTranslation} from "react-i18next";

const withEnhancedT = Comp => ({i18n, lang = undefined, ...props}) =>
    <Comp {...props} t={lang ? (a, b = undefined) => i18n.t(a, {...(b || {}), lang}) : (...x) => i18n.t(...x)} i18n={i18n} />
;

export const themeable = function<P = any>(s: Styles<any, any>, Component: ComponentType<any>) {
    const Comp = component<P & { theme?: string }>(s, withTranslation()(withEnhancedT(Component)));
    return props => (
        <ThemeConsumer>
            {theme => (
                <ThemesConsumer>
                    {themes => (
                        <ThemeProvider theme={old => ({...old, ...(themes[props['theme'] || theme || 'default'] || {})})}>
                            <Comp theme={theme} {...props} />
                        </ThemeProvider>
                    )}
                </ThemesConsumer>
            )}
        </ThemeConsumer>
    );
};

export default themeable