import React from 'react';
import useWidget from '../hooks/useWidget';
import Container from './Container';
import Widget from './Widget';
import Triggers from './Triggers';
import {UIProvider} from '@maarsdev/react-ui';
import translations from '../configs/translations';

export const App = ({className, widgetClassName, config: rawConfig = {}, context = {} }: AppProps) => {
    const props = useWidget(rawConfig, context);
    return (
        <UIProvider theme={props.config.__xid_theme} extraTranslations={translations[props.config.__xid_product]}>
            <Container className={className} {...props}>
                <Widget className={widgetClassName} {...props} />
                <Triggers {...props} />
            </Container>
        </UIProvider>
    );
};

export interface AppProps {
    className?: string,
    widgetClassName?: string,
    config?: any,
    context?: any,
    theme?: string,
}

export default App