import React from 'react';
import screen from "../../../hocs/screen";

export const ErrorScreen = screen({
}, () => (
    <div>
        ERROR
    </div>
));

export default ErrorScreen
