import {useCallback} from "react";

export const useContextSdkMutation = (context, methodName): [({variables: any}) => Promise<any>] => {
    const mutate = useCallback(async ({variables}) => {
        return (context.sdk[methodName])(variables).then(x => Promise.resolve(x));
    }, [context, methodName]);
    return [mutate];
};

export default useContextSdkMutation
