import React, {useCallback, useEffect, useState} from 'react';
import { List } from '@material-ui/core';
import clsx from 'clsx';
import themeable from '../../hocs/themeable';
import SkillRatingListItem from './SkillRatingListItem';

export const SkillRatingList = themeable<SkillRatingListProps>({
}, ({ classes, className, items, maxValues = 5, values = {}, onChange}: SkillRatingListProps) => {
    const [disabled, setDisabled] = useState(false);

    const disabledIfMaxSelected = useCallback((values) => {
        const nbSkillsSelected = values ? Object.keys(values).length : 0;
        if (maxValues && nbSkillsSelected === maxValues) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [maxValues, setDisabled]);

    const handleChange = useCallback(v => {
        const newValues = {...values, ...v};
        Object.keys(newValues).forEach(k => newValues[k] === 0 && delete newValues[k]);
        onChange && onChange(newValues);
        disabledIfMaxSelected(newValues);
    }, [onChange, values, disabledIfMaxSelected]);

    useEffect(() => {
        disabledIfMaxSelected(values);
    }, [values]);

    return (
        <List component={"div"} disablePadding={true} className={clsx(classes.root, className)}>
            {items.map((item, i) => <SkillRatingListItem disabled={disabled} key={item.id || i} {...item} value={values[item.id]} onChange={handleChange} />)}
        </List>
    );
});

export interface SkillRatingListProps {
    classes?: any,
    className?: string,
    items?: any,
    values?: {[id: string]: number},
    onChange?: Function,
    maxValues?: number,
}

export default SkillRatingList