import React from 'react';
import {Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress} from '@material-ui/core';
import clsx from 'clsx';
import {cssBox, cssText} from '@ohoareau/css-utils';
import themeable from '../../hocs/themeable';

export const Button = themeable<ButtonProps>(theme => ({
    root: {
        ...cssText(theme, 'standard', 'button'),
        ...cssBox(theme, 'button'),

        '-moz-osx-font-smoothing': 'grayscale',
        '-webkit-font-smoothing': 'antialiased',
        animationTimingFunction: 'linear',
        transition: 'letter-spacing 300ms ease 0ms',

        borderStyle: 'solid',
        borderColor: '#e0e0e0',
        boxShadow: 'none',

        '&:hover': {
            opacity: 0.8,
            boxShadow: 'none',
        },
        '&:disabled': {
            background: '#8C6EF2',
            color: '#fff',
            opacity: 0.7,
            cursor: 'not-allowed'
        },

        [theme.breakpoints.down('sm')]: {
            padding: '8px'
        },
    },
    stretchable: {
        '&:hover': {
            letterSpacing: 2,
        },
    },
    rounded: {
        borderRadius: 100
    },
    squared: {
        borderRadius: 5
    },
    medium: {
        fontSize: 16,
        borderWidth: 2,
        padding: '8px 50px',
        lineHeight: '19px',
        fontWeight: 'bold',
        letterSpacing: 1,

        '&:hover': {
            borderWidth: 2,
        },
        '&:disabled': {
            borderWidth: 2,
        },
    },
    small: {
        fontSize: 12,
        borderWidth: 1,
        padding: '6px 7px',
        lineHeight: '14px',
        fontWeight: 'normal',
        letterSpacing: 0,

        '&:hover': {
            borderWidth: 1,
        },
        '&:disabled': {
            borderWidth: 1,
        },
    },
    dense: {
        padding: '6px',
    },
    primary: {
        color: '#fff',
        backgroundColor: '#8C6EF2',
        borderColor: '#8C6EF2',

        '&:hover': {
            borderColor: '#8C6EF2',
            backgroundColor: '#8C6EF2',
        }
    },
    primary_contained: {
        color: '#fff',
        backgroundColor: '#8C6EF2',
        borderColor: '#8C6EF2',

        '&:hover': {
            borderColor: '#8C6EF2',
            backgroundColor: '#8C6EF2',
        },
    },
    primary_outlined: {
        color: '#8C6EF2',
        backgroundColor: '#fff',
        borderColor: '#8C6EF2',

        '&:hover': {
            borderColor: '#8C6EF2',
            background: '#fff',
        },
        '&:disabled': {
            borderColor: '#8C6EF2',
            background: '#fff',
            color: '#8C6EF2',
        },
    },
    secondary: {
        background: '#787371',
        color: '#fff',
        borderColor: '#787371',

        '&:hover': {
            color: '#fff',
            background: '#787371',
            borderColor: '#787371',
            boxShadow: '0 0 5px 0 rgba(120, 115, 113, 0.5)',
        },
        '&:disabled': {
            background: '#787371',
        }
    },
    secondary_outlined: {
        color: '#787371',
        backgroundColor: '#fff',
        borderColor: '#787371',

        '&:hover': {
            borderColor: '#787371',
            background: '#fff',
            color: '#787371'
        },
        '&:disabled': {
            borderColor: '#787371',
            background: '#fff',
            color: '#787371',
        },
    },
    active: {
        fontWeight: 'bold',
    },
    secondary_outlined_active: {
        background: '#787371',
        color: '#fff',
        borderColor: '#787371',

        '&:hover': {
            background: '#787371',
            color: '#fff',
            borderColor: '#787371',
        },
    },
}), ({ classes, className, color = 'primary', variant = 'contained', shape = 'rounded', size = 'medium', dense = false, startIcon = null, children, i18n, t = () => {}, tReady, stretchable = false, active, loading = false, loadingLabel = "loading...", ...props }: ButtonProps) => (
    <MuiButton disableRipple={true}
               variant={variant}
               color={color}
               disabled={loading}
               startIcon={loading ? <CircularProgress color={"inherit"} size={size === 'medium' ? 18 : 12} /> : (!!startIcon ? startIcon : null)}
               {...props}
               className={clsx(
                   classes.root,
                   stretchable && classes.stretchable,
                   classes[shape],
                   classes[size],
                   classes[color],
                   classes[variant],
                   dense && classes['dense'],
                   classes[`${color}_${variant}`],
                   active && classes['active'],
                   active && classes[`${color}_${variant}_active`],
                   className
               )}>
        {loading ? loadingLabel : children}
    </MuiButton>
));

export interface ButtonProps extends MuiButtonProps {
    classes?: any,
    i18n?: any,
    t?: Function,
    tReady?: boolean,
    active?: boolean,
    stretchable?: boolean,
    shape?: "rounded" | "squared",
    size?: "small" | "medium",
    loading?: boolean,
    loadingLabel?: string,
    dense?: boolean,
}

export default Button