export default {
    translation: {
        p_skillsProposal_screen_rank_title: "Dites-nous tout sur les compétences que vous souhaiteriez partager",
        p_skillsProposal_screen_comments_title: "Dites-nous tout sur les compétences que vous souhaiteriez partager",
        p_skillsProposal_screen_save_title: "Enregistrement de vos données...",
        p_skillsProposal_screen_thanks_title: "Merci d'avoir participé au défi",
        p_skillsProposal_comments_specify_text: "Précisez votre ou vos expertises.",
        p_skillsProposal_comments_specify_placeholder: "Précisez vos compétences",
        p_skillsProposal_thanks_text_1: "Vos réponses ont été validées",
        p_skillsProposal_thanks_text_2: "Message de remerciement, d'encouragement ou tout autre type de message",
        p_skillsProposal_screen_error_title: "Une erreur s'est produite",
        p_skillsProposal_error_text: "Une erreur s'est produite. Veuillez réessayer ou nous contacter.",
        p_skillsProposal_error_max_skills: "Vous pouvez choisir au maximum 5 compétences"
    },
}