import React from 'react';
import {themeable} from '@maarsdev/react-ui';

export const DefaultProduct = themeable<DefaultProductProps>({
    root: {
    },
}, ({classes = {}}: DefaultProductProps) => (
    <div className={classes.root}>
        DEFAULT
    </div>
));

export interface DefaultProductProps {
    classes?: any,
    className?: string,
}

export default DefaultProduct