import React from 'react';
import {themeable} from '@maarsdev/react-ui';

export const FullsizeDisplay = themeable<FullsizeDisplayProps>({
    root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    }
}, ({classes, children}: FullsizeDisplayProps) => (
    <div className={classes.root}>
        {children}
    </div>
));

export interface FullsizeDisplayProps {
    classes?: any,
    children?: any,
}

export default FullsizeDisplay