import product from "../../hocs/product";
import workflow from '../../workflows/donation';

export const DonationProduct = product(
    workflow,
    {
        onSubmit: values => {
            console.log(values);
        },
    },
    {
        content: {
            padding: 20,
            minHeight: "500px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
        },
    }
);

export default DonationProduct