import React, {useCallback} from 'react';
import {InputBase, FormControl, FormHelperText} from '@material-ui/core';
import themeable from '../../hocs/themeable';
import {cssText, cssBox} from '@ohoareau/css-utils';
import TextareaCircularProgress from '../nucleons/TextareaCircularProgress';
import clsx from 'clsx';

export const Textarea = themeable<TextareaProps>(theme => ({
    root: {
        ...cssText(theme, 'standard', 'textarea'),
        ...cssBox(theme, 'textarea'),

        border: '1px solid #000',
        padding: 0,
        borderRadius: 4
    },
    inputRoot: {
        ...cssBox(theme, 'textarea_input_root'),

        padding: 0,
    },
    inputField: {
        ...cssText(theme, 'standard', 'textarea_input_field'),
        ...cssBox(theme, 'textarea_input_field'),
        '&::placeholder': {
            ...cssText(theme, 'standard', 'textarea_input_field_placeholder'),
            opacity: theme.font_colors_textarea_input_field_placeholder_opacity,
        },

        padding: 10,
    },
    counter: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        gridGap: 6,
        ...cssText(theme, 'standard', 'textarea_counter', 'right'),
        ...cssBox(theme, 'textarea_counter'),

        background: 'rgba(140, 110, 242, 0.15)',
        borderRadius: '0 0 4px 4px',
        padding: 5,
    },
    circle: {
        strokeLinecap: 'round'
    }
}), ({classes = {}, fullWidth = false, className, maxChars = undefined, rows = 4, value, placeholder = '', tReady, onChange = () => {}, t = () => {}, i18n, ...props}: TextareaProps) => {
    const nbChars = (value || '').length || 0;
    const circularValue = Math.min((maxChars && maxChars > 0) ? Math.ceil(100 * (nbChars / (maxChars as number))) : 0, 100);
    const remainingChars = undefined === maxChars ? -1 : ((maxChars || 0) - nbChars);
    const handleChangeValue = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        onChange && onChange(e.target.value || '');
    }, [onChange]);
    return (
        <FormControl hiddenLabel={true} fullWidth={fullWidth} classes={{root: clsx(classes.root, className)}}>
            <InputBase
                {...props}
                multiline
                rows={rows}
                onChange={handleChangeValue}
                value={value}
                margin={"none"}
                placeholder={placeholder}
                classes={{root: classes.inputRoot, input: classes.inputField}}
                inputProps={{"maxLength": maxChars || 0}}
            />
            {maxChars && (
                <FormHelperText component={"div"} classes={{root: classes.counter}}>
                    {!nbChars && <div>{maxChars} caractères maximum</div>}
                    {!!nbChars && <div>{remainingChars} caractère{remainingChars > 1 ? 's' : ''} restant{remainingChars > 1 ? 's' : ''}</div>}
                    <TextareaCircularProgress value={circularValue} />
                </FormHelperText>
            )}
        </FormControl>
    );
});

export interface TextareaProps {
    classes?: any,
    className?: string,
    onChange: Function,
    theme?: string,
    value?: string,
    label?: string,
    rows?: number,
    placeholder?: string,
    maxChars?: number|null,
    tReady?: boolean
    t?: Function,
    i18n?: any,
    fullWidth?: boolean
}

export default Textarea