import React from 'react';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, DialogActions } from '@material-ui/core';
import {Button, DialogTitle} from "../atoms";
import {themeable} from "../../hocs";

export const Dialog = themeable<DialogProps>(theme => ({
    actions: {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
        zIndex: 1,
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 85,
        gridGap: 30,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 10,
            gridGap: 10
        }
    },
}), ({
    onClose,
    classes = {},
    fullWidth = true,
    open = true,
    title,
    children,
    actions = [],
    t = () => {},
    actionChildren
}: DialogProps) => {
    return (
        <MuiDialog maxWidth={'lg'} fullWidth={fullWidth} open={open} onBackdropClick={onClose as any}
                   onClick={e => e.stopPropagation()}>
            <DialogTitle onClose={onClose}>{t(title)}</DialogTitle>
            {children}
            {(!!actionChildren || !!actions) && (
                <DialogActions className={classes.actions}>
                    {actionChildren || null}
                    {actions.filter(x => !!x).map((action, i) =>
                        <Button key={i} disabled={action.disabled} variant={action.variant} color={action.color} onClick={action.onClick}>{t([`dialog_action_${action.label}_label`, action.label])}</Button>
                    )}
                </DialogActions>
            )}
        </MuiDialog>
    );
});

export interface DialogProps extends MuiDialogProps {
    classes?: any,
    title?: string,
    actionChildren?: any,
    className?: string,
    context?: any,
    defaultValues?: any,
    actions?: any[],
    onNext?: Function
    onBack?: Function
    t?: Function
}

export default Dialog