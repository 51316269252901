import React from 'react';
import {DynamicIcon, themeable} from '@maarsdev/react-ui';

export const ConfigIcon = themeable<ConfigIconProps>({
}, ({config, propertyKey, propertyValue = 'icon'}: ConfigIconProps) => {
    if (!config || !config[propertyKey] || !config[propertyKey][propertyValue]) return null;
    return <DynamicIcon type={config[propertyKey][propertyValue]} />;
});

export interface ConfigIconProps {
    config?: any,
    propertyKey: string,
    propertyValue?: string,
}

export default ConfigIcon