import React from 'react';
import {themeable} from '@maarsdev/react-ui';
import useContextSdkItem from '../../hooks/useContextSdkItem';
import {CircularProgress} from '@material-ui/core';
import Dynaform from '@ohoareau/react-dynaform';

export const FormProduct = themeable<FormProductProps>({
    root: {
    },
}, ({ classes = {}, context }: FormProductProps) => {
    const {data: form} = useContextSdkItem(context, 'getPublicFormConfiguration');
    if (!form) return <CircularProgress />;
    return (
        <div className={classes.root}>
            <Dynaform form={form} context={context} />
        </div>
    )
});

export interface FormProductProps {
    classes?: any,
    className?: string,
    context: any,
}

export default FormProduct