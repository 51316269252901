import BaseSdk from './BaseSdk';
import {SourceUserRelationPayload} from "./types";

export class Sdk extends BaseSdk {
    async getPublicWidgetConfiguration(id) {
        return this.restGet(`/public/widgets/${id}`);
    }
    async getPublicFormConfiguration(id) {
        return this.restGet(`/public/forms/${id}`);
    }
    async getPublicUserProfile(id) {
        return this.restGet(`/public/users/${id}`);
    }
    async findPublicSkills() {
        return this.restGet(`/public/skills`);
    }
    async createPublicSkillsShare(data) {
        return this.restPost(`/public/skills-shares`, {data});
    }
    async findPublicExpertsBySkill(id) {
        return this.restGet(`/public/skills/${id}/experts`);
    }
    async findPublicSkillsByUser(id) {
        return this.restGet(`/public/users/${id}/skills`);
    }
    async createPublicSourceUserRelation(data: SourceUserRelationPayload) {
        return this.restPost(`/public/sources/${data.sourceCode}/users/${data.sourceUserId}/relations`, {data});
    }
}

export default Sdk