import {useEffect} from "react";

export const useWorkflowStateSdkItemPage = (context, state, updateState, stateKey, methodName, args = {}): {data: {items: any[]}|undefined, loading: boolean} => {
    useEffect(() => {
        if (!state || !state[stateKey]) {
            (context.sdk[methodName])(args).then(page => {
                updateState({[stateKey]: page});
            });
        }
    }, [context, methodName, state, args])
    return {data: (state || {})[stateKey], loading: undefined === (state || {})[stateKey]};
};

export default useWorkflowStateSdkItemPage
