import {plugins} from '../configs';
import {config} from '../types';

const xidMap = ['product', 'format', 'display', 'partner', 'theme', 'locale'];
const xidStatesMap = ['opened', 'visible'];

const getPluginNameByTypeAndCode = (type, code) => (plugins[type] || {})[code] || 'default';

const parseConfigXid = (rawConfig, config) => {
    config.xid = rawConfig.xid || '';
    const [xid0, xid1 = ''] = config.xid.split(/-/);
    config.__xid = xid0.match(/.{1,2}/g) || [];
    xidMap.reduce((acc, v, i) => {
        acc[v] = (rawConfig[v] && ('object' === typeof rawConfig[v])) ? rawConfig[v] : {};
        acc[`__xid_${v}`] = getPluginNameByTypeAndCode(v, config.__xid[i]);
        return acc;
    }, config);
    config.__xidstates = xid1.split('').map(x => parseInt(x));
    config.__xidstates.reduce((acc, v, i) => {
        const key = xidStatesMap[i] || undefined;
        if (key) {
            switch (v) {
                case 0: acc[key] = false; break;
                case 1: acc[key] = true; break;
                default: break;
            }
        }
        return acc;
    }, config);
}

const parseConfigFeatures = config => {
    if (!config.features) return;
    return Object.keys(config.features).reduce((acc, [k, v]) => {
        // @todo load feature k into acc
    }, config);
};

export const buildConfig = async (rawConfig, ctx): Promise<config> => {
    const config = {configured: true, visible: true, opened: false};
    parseConfigXid(rawConfig, config)
    parseConfigFeatures(config);
    return {...config} as any as config;
};

export default buildConfig