import React, {useCallback, useEffect} from 'react';
import {CircularProgress} from "@material-ui/core";
import {themeable} from '@maarsdev/react-ui';
import useContextSdkMutation from "../../../hooks/useContextSdkMutation";

export const SaveScreen = themeable({
    loading: {
        minHeight: 300,
        padding: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
}, ({context, state, classes, onNext}: any) => {
    const [save] = useContextSdkMutation(context, 'createPublicSkillsShare');
    const onSave = useCallback(() => {
        save({variables: {
                email: context.email || 'widget@maars.cc',
                firstName: context.firstName || 'FirstName',
                lastName: context.lastName || 'LastName',
                values: Object.entries(state.skills).reduce((acc, [k, v]) => {
                    acc.push({skill: k, level: v, comment: (state.comments || {})[k]});
                    return acc;
                }, [] as any[]),
                source: context.source || 'skills_proposal_widget',
                sourceUser: context.user || '0',
            }}).then(() => {
            onNext();
        }).catch (e => {
            console.error(e);
        })
    }, [save, onNext, state]);
    useEffect(() => {
        onSave();
    }, []);
    return <div className={classes.loading}><CircularProgress size={100} style={{color:"#8c6ef2"}} /></div>;
});

export default SaveScreen;