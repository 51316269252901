export default {
    componentRepository: 'skillExpertSearch',
    defaultScreen: 'welcome',
    screens: {
        welcome: {
            title: "p_skillExpertSearch_screen_welcome_title",
            actions: [
                {type: 'secondary', label: 'cancel', target: '@end'},
                {type: 'primary', label: 'next', target: '@next'},
            ],
            transitions: [
                {screen: 'skills'}
            ]
        },
        skills: {
            title: "p_skillExpertSearch_screen_skills_title",
            actions: [
                {type: 'secondary', label: 'back', target: '@back'},
            ],
            transitions: [
                {screen: 'experts'},
            ]
        },
        experts: {
            title: "p_skillExpertSearch_screen_experts_title",
            actions: [
                {type: 'secondary', label: 'back', target: '@back'},
            ],
            transitions: [
                {screen: 'expert', test: ({selectedExpert}) => !!selectedExpert},
                {screen: 'end', test: ({selectedExpert}) => !selectedExpert}
            ]
        },
        expert: {
            title: "p_skillExpertSearch_screen_expert_title",
            actions: [
                {type: 'secondary', label: 'back', target: '@back'},
            ],
            transitions: [
                {screen: 'end'}
            ]
        },
        end: {
            title: "p_skillExpertSearch_screen_end_title",
            actions: [
                {type: 'secondary', label: 'close', target: '@end'},
            ],
            transitions: [
                {screen: '@submit'}
            ]
        },
        error: {
        }
    }
}