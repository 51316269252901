import * as React from "react";

function SvgArrowTriangleRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 32 32"
      width={props.width || 24}
      height={props.height || 24}
      fill="currentColor"
      {...props}
    >
      <path d="M13.894 23.726l7.652-7.016a.94.94 0 000-1.414c-.012-.01-.028-.014-.04-.024l-7.612-7a1.176 1.176 0 00-1.552 0c-.002.004-.004.01-.008.014A.951.951 0 0012 9v14c0 .286.136.54.346.722l-.004.004c.43.392 1.124.392 1.552 0z" />
    </svg>
  );
}

export default SvgArrowTriangleRight;
