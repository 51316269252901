import React from 'react';
import {Box, Typography} from '@material-ui/core';
import {DynamicIcon} from '@maarsdev/react-ui';
import screen from "../../../hocs/screen";

export const ThanksScreen = screen(theme => ({
    root: {
        minHeight: 300,
        display: 'flex',
        flexDirection: 'column',
        padding: '120px 20px',
        gridGap: 60,
        gap: 60,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '30px 20px',
            gridGap: 30,
            alignItems: 'center',
        }
    },
    thanksTitle: {
        fontFamily: "Fredoka One, Arial Black, sans-serif",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '120%',
        textAlign: 'center'
    },
}), ({ classes = {}, t }: any) => (
    <Box className={classes.root}>
        <Typography classes={{root: classes.thanksTitle}} variant={"h6"} component={"h6"}>
            {t('p_skillsProposal_thanks_text_1')}
        </Typography>
        <DynamicIcon type={'check'} />
        <Typography className={classes.thanksTitle} variant={"h6"} component={"h6"}>
            {t('p_skillsProposal_thanks_text_2')}
        </Typography>
    </Box>
));

export default ThanksScreen