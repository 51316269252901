import React, {useCallback} from 'react';
import {Grid, Typography} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import {themeable, Textarea} from '@maarsdev/react-ui';

const SkillCommentTextarea = themeable(theme => ({
    itemWrapper: {
        padding: '15px',
        '&:nth-child(even)': {
            background: '#fafafa'
        }
    },
    item: {
        maxWidth: 1024
    },
    title: {
        fontSize: 16
    },
    textareaGrid: {
        [theme.breakpoints.down('sm')]: {
            flex: 1
        }
    },
    textarea: {
        minWidth: 350,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%'
        }
    },
}), ({classes, t = () => {}, rank, id, name, value, onChange}) => {
    const handleChange = useCallback(value => {
        onChange(id, value);
    }, [onChange, id]);
    return (
        <Grid className={classes.itemWrapper} alignItems={"center"} container direction={"column"}>
            <Grid className={classes.item} direction={"row"} container justify={"space-around"}>
                <Grid item xs={12} sm={4}>
                    <Typography component={"h2"} className={classes.title}>{name}</Typography>
                    <Rating readOnly value={rank} />
                    <Typography>{t('p_skillsProposal_comments_specify_text')}</Typography>
                </Grid>
                <Grid item xs={12} sm={8} className={classes.textareaGrid}>
                    <Textarea
                        classes={{root: classes.textarea}}
                        maxChars={280}
                        value={value}
                        onChange={handleChange}
                        placeholder={t('p_skillsProposal_comments_specify_placeholder')} />
                </Grid>
            </Grid>
        </Grid>
    )
});

export const CommentsScreen = themeable({
    root: {
        minHeight: 300,
    },
}, ({ classes = {}, state, onUpdateState}: any) => {
    const handleChange = useCallback((id, value) => {
        onUpdateState({comments: {...state.comments, [id]: value}});
    }, [onUpdateState, state]);
    return (
        <Grid className={classes.root} direction={"column"} alignItems={"center"} container>
            {Object.entries(state.skills || {}).map(([id, rank]: [any, any], i) => {
                const skill = ((state.allSkills || {}).items || []).find(s => s.id === id);
                return (
                    <SkillCommentTextarea key={`skill-${id || i}`}
                                          name={skill.name}
                                          id={id}
                                          value={(state.comments || {})[id]}
                                          onChange={handleChange}
                                          rank={rank}
                    />
                );
            })}
        </Grid>
    )
});

export default CommentsScreen;