import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import defaultResources from './configs/translations';

export const factory = ({fallbackLng = 'fr-FR', lng = "fr-FR", resources = defaultResources, extraResources = {}}: {fallbackLng?: string, lng?: string, resources?: any, extraResources?: any}) => {
    const i = i18n.createInstance();
    i
        .use(initReactI18next)
        .init({
            resources,
            fallbackLng,
            lng,
            keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
            react: {
                wait: true,
            },
        }).then(() => {
            Object.entries(extraResources).forEach(([lng, bundles]) => {
                Object.entries((bundles as any) || {}).forEach(([ns, trans]) => {
                    i.addResourceBundle(lng.replace('_', '-'), ns, trans || {});
                })
            });
        })
    ;
    return i;
};

export default factory