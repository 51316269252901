import * as React from "react";

function SvgStarSelected(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 41 39"
      fill="none"
      width={props.width || 35}
      height={props.height || 34}
      fontSize={props.fontSize || "inherit"}
      {...props}
    >
      <path
        d="M20.5 0l6.334 12.838L41 14.898l-10.25 9.991L33.17 39 20.5 32.338 7.83 39l2.42-14.11L0 14.896l14.165-2.06L20.5 0z"
        fill="#F0B243"
      />
    </svg>
  );
}

export default SvgStarSelected;
