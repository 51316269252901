import React from 'react';
import component from '@maarsdev/react-component';
import clsx from 'clsx';

export const Container = component<ContainerProps>({
    root: {
    },
}, ({classes = {}, visible = true, opened = false, className, children}: ContainerProps) => {
    if (!visible && !opened) return null;
    return (
        <div className={clsx(classes.root, className)}>
            {children}
        </div>
    );
});

export interface ContainerProps {
    visible?: boolean,
    opened?: boolean,
    classes?: any,
    className?: string,
    children?: any,
}

export default Container