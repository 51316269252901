import React from 'react';
import {Typography} from '@material-ui/core';
import screen from "../../../hocs/screen";

export const WelcomeScreen = screen({
    root: {
        flexDirection: "column",
        gridGap: 40
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: "Fredoka One"
    }
}, ({classes = {}, t}: any) => (
    <div className={classes.root}>
        <Typography className={classes.title}>{t('p_donation_welcome_title')}</Typography>
    </div>
));

export default WelcomeScreen